import * as busi from './busi'


export const calcTodaysStoreItems = (storeroom0) => {
    if(storeroom0===undefined) return storeroom0

    let storeroom = storeroom0
    let today = busi.dateNoTime(new Date());

    let refDate = busi.dateNoTime(storeroom.refDate)
    let diffDays = today.getDate() - refDate.getDate();
    console.log("diffDays: " +diffDays)
    
    // recalc stock of each item based on diffDays
    let storeItemList=storeroom.storeItemList.map(storeItem=>{
        let onStock=Math.max(0,storeItem.onStock-diffDays)
        return {...storeItem, onStock}
    })

    return {...storeroom0, storeItemList, refDate:today.getTime()}
}

const parseAmount = (storeItemLabel) => {
    let groups = storeItemLabel.match(/^([0-9.]+)[ ]?(.*)/);
    if(groups==null) return 1;
    let amountRaw= parseFloat(groups[1])
    return Math.round(20*parseFloat(amountRaw))/20
}

/** remove amount for sheetItemLabel */
const parseLabel = (storeItemLabel) => {
    let groups = storeItemLabel.match(/^([0-9.]+)[ ]?(.*)/);
    if(groups==null) return storeItemLabel;
    return groups[2]
}

export const storeToSheetItem = (storeItem) => {
    let amount = parseAmount(storeItem.label)
    let label = parseLabel(storeItem.label)
    return {amount, label, productLine:storeItem.productLineKey, done:false}
}

export const refillStoreRoom = (storeItem, storeroom) => {
    let storeItemList = storeroom.storeItemList.map(s=>{
        if(s.label===storeItem.label) {
            let onStock = parseInt(s.onStock)+parseInt(s.oneBuyDuration)
            return {...s, onStock}
        }
        else {
            return s
        }
    })
    return {...storeroom, storeItemList}
}

import { createSlice, createAction } from '@reduxjs/toolkit';



export const guiSlice = createSlice({
  name: 'gui',
  initialState: {},
  reducers: {
    showStoreItemDetail: (gui, action) => ({...gui, storeItemDetail:{...action.payload.storeItem, storeItemBeforeEdit:action.payload.storeItemBeforeEdit}}),
    showSheetItemPopup: (gui, action) => ( {...gui, sheetItemPopup:{...action.payload.sheetItem, sheetItemBeforeEdit:action.payload.sheetItemBeforeEdit}}),
    closeSheetItemPopup: (gui) => ({...gui, sheetItemPopup:undefined}),
    getPlanFailed: (gui, action) => {
      return {...gui, error:action.payload.message}},
    toggleShowDoneSheetItems: (gui) => {
      let showDoneSheetItems = gui.showDoneSheetItems||false
      return ({...gui, showDoneSheetItems:!showDoneSheetItems})}
      ,
    showMenuChoice: (gui, action) => {
      // hide meal-choice if meal-date is re-selected
      if(gui.menuChoice!== undefined) {
        if(gui.menuChoice.selectedDate===action.payload.selectedDate &&  gui.menuChoice.selectedMeal===action.payload.selectedMeal)
          return{...gui, menuChoice:undefined}
      }
      return{...gui, menuChoice:{selectedDate:action.payload.selectedDate, selectedMeal:action.payload.selectedMeal}}},

    hideMenuChoice: (gui) => ({ ...gui, menuChoice: undefined }),
    setUsername: (gui, action) => ({ ...gui, username: action.payload }),
    newRecipe: (gui) => ({ ...gui, recipeDetail:{recipeLabel: "Neues Menu", recipeItemList:[{label:" artikel", amount:1, productLine:"kon"}] }}),
    editReceipe:(gui, action) => {
      return {...gui, recipeDetail:{recipeLabel:action.payload.recipe.label,
        recipeItemList:[...action.payload.recipe.recipeItemList, {label:" artikel", amount:1, productLine:"kon"}],
        recipeBeforeEdit:action.payload.recipe}}},
    rmRecipeItem: (gui, action) => {
      let recipeItemList = [...gui.recipeDetail.recipeItemList ]
      recipeItemList.splice(action.payload.recipeItemIndex,1)
      return {...gui, recipeDetail:{...gui.recipeDetail, recipeItemList}}
    },
    setRecipeLabel: (gui, action) => {
      let recipeLabel= action.payload.recipeLabel
      return {...gui, recipeDetail:{...gui.recipeDetail, recipeLabel}}
    },
    setRecipeItemChange: (gui, action) => {
      let recipeItemList = [...gui.recipeDetail.recipeItemList ]
      recipeItemList[action.payload.recipeItemIndex]= action.payload.recipeItem
      if(recipeItemList.length===0 ||recipeItemList[recipeItemList.length-1].label!==" artikel") {
        recipeItemList.push({label:" artikel", amount:1, productLine:"kon"})
      }
      return {...gui, recipeDetail:{...gui.recipeDetail, recipeItemList}}
    },
    addRecipeItemList: (gui, action) => {
      let recipeItemList = [...gui.recipeDetail.recipeItemList, ...action.payload.recipeItemList ]
      let recipeDetail={...gui.recipeDetail, recipeItemList}
      delete recipeDetail.area
      return {...gui, recipeDetail}
    },
    cancelRecipe: (gui) => ({...gui, recipeDetail:undefined}),

  },
  extraReducers: builder => {
    builder.addCase(createAction('plan/getPlanRsp'), (gui, action) => {
      return {...gui, username:action.payload.username}
    })
    builder.addCase(createAction('plan/getRecipeRsp'), (gui, action) => {
      return {...gui, username:action.payload.username}
    })
    builder.addCase(createAction('plan/mrgSheetItem'), (gui) => {
      return {...gui, sheetItemPopup:undefined}
    })
    builder.addCase(createAction('plan/removeAllDoneSheetItems'), (gui) => {
      return {...gui, showDoneSheetItems:false}
    })
    builder.addCase(createAction('plan/addGear'), (gui) => {
      return {...gui, menuChoice:undefined}
    })
    builder.addCase(createAction('plan/newStoreItem'), gui => ({...gui, storeItemDetail:{ storeItemBeforeEdit:{}} }))
    builder.addCase(createAction('plan/setStoreItemDetail'), gui => ({...gui, storeItemDetail:undefined}))
    builder.addCase(createAction('plan/removeStoreItemDetail'), gui => ({...gui, storeItemDetail:undefined}))
    builder.addCase(createAction('plan/unshowStoreItemDetail'), gui => ({...gui, storeItemDetail:undefined}))
    builder.addCase(createAction('recipe/setEditedRecipe'), gui => ({...gui, recipeDetail:undefined}))
    builder.addCase(createAction('recipe/deleteEditedRecipe'), gui => ({...gui, recipeDetail:undefined}))
  }
});

export const { showStoreItemDetail, showSheetItemPopup, closeSheetItemPopup, 
               getPlanFailed, toggleShowDoneSheetItems, showMenuChoice, hideMenuChoice,
               setUsername, 
               newRecipe, editReceipe, rmRecipeItem, setRecipeLabel, setRecipeItemChange, addRecipeItemList,
               cancelRecipe
            } = guiSlice.actions;

export const selectGui = state => state.gui;
export const selectSheetItemBeforeEdit = state => {
  if(!state.gui.sheetItemPopup) return undefined
  return state.gui.sheetItemPopup.sheetItemBeforeEdit
};
export const selectStoreItemDetail = state => {
  return state.gui.storeItemDetail
};

export default guiSlice.reducer;

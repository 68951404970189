import React from 'react'
import {useDispatch} from 'react-redux'
import StoreItem from './StoreItem'
import StoreItemDetail from './StoreItemDetail'
import {useSelector} from 'react-redux'
import {selectStoreItemDetail} from '../main/guiSlice.js'
import * as plan from './planSlice'
import styles from '../main/Main.module.css'

const StoreRoom = ({storeroom}) => {
    const dispatch=useDispatch()
    const storeItemDetail = useSelector(selectStoreItemDetail)

    return <div className={styles.scrollTable}>
        <div className={styles.epTableRow} style={{width:"100%"}}> 
        <button   style={{display:"table-cell", width:"100%", padding:"5px", margin:"5px"}} key="addStoreItem" type="button"
                  onClick={()=>dispatch(plan.newStoreItem())}
        >Add...</button></div>
        <div className={styles.epTable}>
        {storeroom.storeItemList.map(storeItem => {
            if(storeItemDetail!==undefined 
                && storeItemDetail.storeItemBeforeEdit!==undefined
                && (storeItemDetail.storeItemBeforeEdit.label===storeItem.label || storeItem.label==='')) {
                return <StoreItemDetail key={storeItem.label} storeItemDetail={storeItemDetail} />
            }
            return <StoreItem storeItem={storeItem} key={storeItem.label} />
        })}
        </div></div>
}

export default StoreRoom

import React, {useState} from 'react'
import styles from '../main/Main.module.css'
import {useDispatch, useSelector} from 'react-redux'
import {closeSheetItemPopup, selectSheetItemBeforeEdit} from '../main/guiSlice.js'
import {mrgSheetItem, selectProductLine} from '../plan/planSlice.js'
import {getAmountLabel} from '../plan/busi'

const SheetItemPopup = ({sheetItemPopup}) => {
    const dispatch = useDispatch()
    const productLines = useSelector(selectProductLine||'')
    const sheetItemBeforeEdit = useSelector(selectSheetItemBeforeEdit)

    const [amountLabel, setAmountLabel] = useState("1 artikel")
    const [productLine, setProductLine] = useState(sheetItemPopup.productLine)

    let title="Artikel hinzufügen"
    if(sheetItemBeforeEdit) {
        title="Artikel ändern"
    }

    return (
        <div className={styles.popup}><div className={styles.popup_inner}>
            <h2>{title}</h2>
            <select value={productLine} onChange={e=>{e.preventDefault();setProductLine(e.target.value)}} >
                {Object.keys(productLines).map(pr => (<option value={pr} key={pr}>{productLines[pr]}</option>))}
            </select>
            <input autoFocus type="text" value={amountLabel} onChange={e=>{e.preventDefault();setAmountLabel(e.target.value)}} />
            <button type="button" onClick={()=>onOk(dispatch, amountLabel, productLine, sheetItemBeforeEdit)}>OK</button>
            <button type="button" onClick={()=>dispatch(closeSheetItemPopup())}>Abbrechen</button>
        </div></div>
    )
}

const onOk = (dispatch, amountLabel, productLine, sheetItemBeforeEdit) => {
    let amountLabelJson=getAmountLabel(amountLabel)
    dispatch(mrgSheetItem({sheetItem:{...amountLabelJson, productLine, done:false, sheetItemBeforeEdit}}))
}

export default SheetItemPopup
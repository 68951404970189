import React from 'react'
import  { useDispatch, useSelector } from 'react-redux'
import * as gui from '../main/guiSlice';
import * as plan from '../plan/planSlice';
import {  selectRecipeList } from '../recipe/recipeSlice';
import { getRecipeByLabel } from '../recipe/busiRecipe'
import styles from '../main/Main.module.css'



const MenuChoice = () => {
  const dispatch = useDispatch()
  const menuChoice=useSelector(gui.selectGui).menuChoice

  const recipeList=useSelector(selectRecipeList)
  let menuNameList=recipeList.map(recipe=>recipe.label);

  return  <div> 
    <div className={styles.epTableRow} style={{width:"100%"}}> 
      <button   style={{display:"table-cell", width:"100%", padding:"5px", margin:"5px"}} key="addStoreItem" type="button"
                onClick={()=>dispatch(gui.newRecipe())}
      >Add...</button></div>
    <div className={styles.scrollTable}>            
      <div className={styles.epTable}>
      {menuNameList.map(menuName => <div className={styles.epTableRow} key={menuName}>
        <div style={{display:"flex", width:"100%"}}>
        <button type="button" className={styles.epTableRowButton}
          onClick={()=>{onSetMenu(dispatch, menuName, menuChoice, recipeList)}}>{menuName}</button>
        <button type="button"  
          onClick={()=>{onEditRecipe(dispatch, menuName, recipeList)}}>...</button>
      </div></div>)}
    </div></div>
  </div>
  }

const onSetMenu = (dispatch, menuName, menuChoice, recipeList) => {
  let recipeIdx=recipeList.findIndex(r=>r.label===menuName)
  if(recipeIdx<0) {
    // recipe not found. pass without
    dispatch(plan.addGear(menuName, menuChoice))
  }
  else {
    dispatch(plan.addGear({menuName, menuChoice, recipe:recipeList[recipeIdx]}))
  }
}

const onEditRecipe = (dispatch, menuName, recipeList) => {
  let recipe = getRecipeByLabel(recipeList, menuName)
  dispatch(gui.editReceipe({recipe}))
}

export default MenuChoice
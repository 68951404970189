import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import saga from './epSaga.js'

import planSlice from '../features/plan/planSlice';
import recipeSlice from '../features/recipe/recipeSlice';
import guiSlice from '../features/main/guiSlice.js';

const devMode = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

if (devMode) {
  middleware.push(logger);
}


  const store = configureStore({
    reducer: {
      plan: planSlice,
      recipe: recipeSlice,
      gui: guiSlice,
    },
    devTools: devMode,
    middleware,
  });
  sagaMiddleware.run(saga);

  export default store
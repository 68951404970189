/* {label, productLine, done} */
export const mergeSheetItem = (mergeSheetItem, sheetItemList) => {
    let index = sheetItemList.findIndex(s => s.label===mergeSheetItem.label 
        && s.done===mergeSheetItem.done)
    if(index===-1) {
        let amount = parseFloat(mergeSheetItem.amount)
        // missing amount either "one piece" or "a little"
        if(Number.isNaN(amount)) {
            amount=1.0
        }
        if(amount>0.0) {
            // add new item
            sheetItemList.push(mergeSheetItem)
        }
    }
    else {
        let mergeItem = parseFloat(mergeSheetItem.amount)
        if(Number.isNaN(mergeItem)) {
            mergeItem=1.0
        }
        let amount = mergeItem + parseFloat(sheetItemList[index].amount)
        amount = Math.round(amount*100)/100 
        if(amount<0.01) {
            // extinct existing item (amount near zeor or negative)
            sheetItemList.splice(index, 1)        
        }
        else {
            // sum of amounts
            sheetItemList[index] = {...mergeSheetItem, amount}
        }
    }
    return sheetItemList
}

export const getSelectedMeal = (dayPlan, menuChoice) => {
    if(menuChoice!==undefined && dayPlan!==undefined) {
        // dayPlan.date omes in so many flavors!
        // date with differing precisions and string of those...
        if(dateNoTime(new Date(dayPlan.date)).getTime()===menuChoice.selectedDate) {
            return menuChoice.selectedMeal
        }        
    }
    return undefined
}

export const isSelectedMeal = (date, mealName, menuChoice) => {
    if(menuChoice!==undefined && date!==undefined) {
        // dayPlan.date omes in so many flavors!
        // date with differing precisions and string of those...
        if(dateNoTime(new Date(date)).getTime()===menuChoice.selectedDate) {
            return mealName ===menuChoice.selectedMeal
        }        
    }
    return false
}

export const removeGear = (dayPlanList, menuChoice, recipeLabel) => {
    return dayPlanList.map(dayPlan =>{
        let selectedMealTst=getSelectedMeal(dayPlan, menuChoice)
        if(selectedMealTst!==undefined) {
            if(menuChoice.selectedMeal==='lunch') {
                let lunch = removeGearFromMeal(dayPlan.lunch, recipeLabel)
                return {...dayPlan, lunch}
            }
            else {
                let dinner = removeGearFromMeal(dayPlan.dinner, recipeLabel)
                return {...dayPlan, dinner}
            }
        }
        else {
            return dayPlan
        }
    })
}

const removeGearFromMeal= (meal, recipeLabel) => {
    let gearIdx=meal.gearList.findIndex(r=>r.recipeLabel===recipeLabel)
    if(gearIdx<0) return meal
    let gearList=[...meal.gearList]
    gearList.splice(gearIdx, 1)
    return {...meal, gearList}
}
export const dateToStr = (date) => {
    const dateFormat = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    let wtfDate = new Date(date)
    return wtfDate.toLocaleDateString('de-DE', dateFormat)
}

export const dateNoTime = (date) => {
    let dateHour = new Date(date) // get rid of possibly wrong hour
    let dateNoTime = new Date(dateHour.setHours(0, 0, 0, 0))
    return dateNoTime

} 
export const filterCurrentDates = (dayPlanList) => {
    if(dayPlanList===undefined) return dayPlanList
    let today = dateNoTime(new Date());
    let date = dateNoTime(new Date());

    // ensure dates have no time
    dayPlanList = dayPlanList.map(dayPlan => {
        let date = dateNoTime(dayPlan.date);
        return {...dayPlan, date}
    })

    let dateAry = [0,1,2,3,4,5,6].map(offset => new Date(date.setDate(today.getDate()+offset)))

    let actPlanList = dateAry.map(date => {
        const idx = dayPlanList.findIndex(dayPlan => {
            return dayPlan.date.getTime()===date.getTime()
        })
        if(idx>=0) {
            return dayPlanList[idx]
        }
        return {date}
    });
    return actPlanList;
}

export const getAmountLabel = (labelAmountStr) => {
    labelAmountStr=labelAmountStr.replace(/\t/g, ' ')
    const regex = /([0-9.]*)(.*)/;
    let match = labelAmountStr.match(regex);
    return { amount: match[1], label: match[2] };
}

export const getMultiline = (labelAmountStr) => {
    return labelAmountStr.split(/\r?\n/)
}

import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as gui from '../main/guiSlice'
import * as plan from '../plan/planSlice'
import * as busi from '../plan/busi'

const RecipeItem =({recipeItem, index}) => {
    let val = ""+recipeItem.amount+recipeItem.label    

    const productLines = useSelector(plan.selectProductLine||'')
    const dispatch = useDispatch()

    return (<div style={{display:"flex"}}>
        <div style={{display:"flex", width:"100%", paddingLeft:"30px", paddingRight:"20px"}}>
            <button type="button" key="b"
                   onClick={()=>{return dispatch(gui.rmRecipeItem({recipeItemIndex:index}))}}
            >X</button>
          <input style={{width:"100%"}} 
                 value={val} 
                 onChange={e=>{return onSetRecipeItemChange(e, dispatch, index, recipeItem);
                 }}/>
        </div> 
        <select value={recipeItem.productLine} style={{padding:5}}
                onChange={e=>{e.preventDefault()
                    let payload={ recipeItemIndex: index, recipeItem: { ...recipeItem,  productLine:e.target.value}}
                    return dispatch(gui.setRecipeItemChange(payload))
                    }} >
        >           
            {Object.keys(productLines).map(pr => (<option value={pr} key={pr}>{productLines[pr]}</option>))}
        </select>
    </div>)
}

const  onSetRecipeItemChange = (e, dispatch, index, recipeItem) => {
    e.preventDefault();
    let amountLabelStr=e.target.value
    let amountLabel = busi.getAmountLabel(amountLabelStr);

    let payload={ recipeItemIndex: index, recipeItem: { ...recipeItem,  ...amountLabel}}
    return dispatch(gui.setRecipeItemChange(payload));
}

export default RecipeItem



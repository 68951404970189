import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styles from '../main/Main.module.css'
import {  selectProductLine, removeAllSheetItems, removeAllDoneSheetItems } from './planSlice';
import {  selectGui, toggleShowDoneSheetItems } from '../main/guiSlice';
import ProductlineSheetItems from './ProductlineSheetItems.js'

const Sheet = ({ sheetItemList}) => {
    const dispatch = useDispatch()
    const productLine=useSelector(selectProductLine)
    const gui=useSelector(selectGui)
    /**
     * pl: productLine
     */
    // productLine={"kon":"Pasta, Konserven","fru":"Früchte & Gemüse","bak":"Bäckerei, Gebäck, Mehl","mil":"Milchprodukte"}

    if(!productLine) return null
    if(sheetItemList.length===undefined) return  null
    let isShowActive=!(gui.showDoneSheetItems||false)

    /* "relevant": all "done" or all "non-done" */
    let sheetItemListRelevant = sheetItemList.filter(i => (i.done!==isShowActive))

    /* sheetItems grouped by productLine*/
    let plItemList = {}    

    sheetItemListRelevant.forEach(sheetItem => {
        let plKey = sheetItem.productLine

        // create or add to list
        plItemList[plKey] = [...(plItemList[plKey]||[]), sheetItem]
        
    })

    let plKeys = Object.keys(productLine)

    if(isShowActive)
    return (
        /** forall ProductLines: show active SheetItems (incl. empty ProductLines for "add.."-Button)*/
        <div className={styles.defaultBackground}>
            <div className={styles.scrollTable}>            
                <div className={styles.epTable}>
                    {plKeys.map( plKey => (
                            <ProductlineSheetItems key={plKey} productLineSheetItems={{plKey, plLabel:productLine[plKey], items:plItemList[plKey]}} 
                                isShowActive={true}/>
                        ))                    
                    }
                </div>
            </div>
            <span>
                <button type="button" onClick={()=>dispatch(removeAllSheetItems())}>alle definitiv löschen</button>
                <button type="button" onClick={()=>dispatch(toggleShowDoneSheetItems())}>erledigte</button>
            </span>
        </div>
    )
    else
    return (
        /** forall ProductLines: show done SheetItems (only ProductLines with items) */
        <div className={styles.alternateBackground}>
            <div className={styles.scrollTable}>            
                <div className={styles.epTable}>
                    {plKeys.map( plKey => (
                            <ProductlineSheetItems key={plKey} productLineSheetItems={{plKey, plLabel:productLine[plKey], items:plItemList[plKey]}} 
                            isShowActive={false}/>
                        ))                    
                    }
                </div>
            </div>
            <span>
                <button type="button" onClick={()=>dispatch(removeAllDoneSheetItems())}>alle löschen</button>
                <button type="button" onClick={()=>dispatch(toggleShowDoneSheetItems())}>zurück</button>
            </span>
        </div>
    )

}

export default Sheet
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import styles from '../main/Main.module.css'
import * as gui from '../main/guiSlice';
import * as recipe from '../recipe/recipeSlice';
import * as busi from '../plan/busi'
import RecipeItem from './RecipeItem';

const RecipeDetail = ({ recipeDetail}) => {
    const dispatch = useDispatch()
    const [area/*, setArea*/] = useState(recipeDetail.area ||'')

    return <div className={styles.scrollTable}>
        <input style={{width:"95%",fontWeight:"bold"}} type="text" value={recipeDetail.recipeLabel} onChange={e=>{
            e.preventDefault();
            dispatch(gui.setRecipeLabel({recipeLabel:e.target.value}))
        }} />
        <p/>
        <div className={styles.epTable}>
            {recipeDetail.recipeItemList.map((recipeItem, index) => {
                return <RecipeItem recipeItem={recipeItem} index={index} key={"RecipeItem"+index} />
            })}
        </div>
        <textarea value={area} placeholder="Zutaten ab Clipboard pasten" rows={6} style={{width:"95%"}}
                  onChange={e => onAddRecipeItemList(e, dispatch)}/>
        <div style={{display:"flex"}}>
            <button   style={{display:"table-cell", width:"50%", padding:5, margin:5}} type="button"
                onClick={()=>dispatch(gui.cancelRecipe())}
            >Abbrechen</button>
            <button   style={{display:"table-cell", width:"50%", padding:5, margin:5}} type="button"
                    onClick={()=>dispatch(recipe.deleteEditedRecipe({recipeDetail}))}
                    disabled={false } 
            >Löschen</button>
            <button   style={{display:"table-cell", width:"50%", padding:5, margin:5}} type="button"
                    onClick={()=>dispatch(recipe.setEditedRecipe({recipeDetail}))}
                    disabled={false } 
                >OK</button>
        </div>
        
        </div>

}

const  onAddRecipeItemList = (e, dispatch) => {
    e.preventDefault();
    let amountLabelStr=e.target.value
    let multilineAry=busi.getMultiline(amountLabelStr)
    let recipeItemList = []
    multilineAry.forEach(function (amountLabelStr, mIndex) {
        if(amountLabelStr) {
            let amountLabel = busi.getAmountLabel(amountLabelStr);
            let newRecipeItem= { productLine:'kon',  ...amountLabel}

            recipeItemList.push(newRecipeItem)
        }
    })

    let payload={ recipeItemList}
    return dispatch(gui.addRecipeItemList(payload));
}

export default RecipeDetail
import React from 'react'
import Dayplan from './DayPlan.js'
import styles from '../main/Main.module.css'

/* date -d "May 15 2020" +%s  + 000  => 1589493600000 */

const DayPlanList = ({dayPlanList}) => {

    return (<div className={styles.scrollTable}>
        {dayPlanList.map((dayPlan, index) => <Dayplan dayPlan={dayPlan} key={index} />)}
        </div>
        )
}

export default DayPlanList;

/**
 * find recipe and list its items
 * @param {*} recipeList 
 * @param {*} recipeLabel 
 */
export const getRecipeItemList = (recipeList, recipeLabel) => {
    let recipe = getRecipeByLabel(recipeList, recipeLabel)
    if(!recipe) return []
    return recipe.recipeItemList
}

export const getRecipeByLabel = (recipeList, recipeLabel) =>  {
    return recipeList.find(r=>r.label===recipeLabel)
} 

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'
import * as guiSlice from '../main/guiSlice'
import MenuChoice from '../menu/MenuChoice'
import DayPlanList from './DayPlanList'
import Sheet from './Sheet'
import StoreRoom from './StoreRoom'
import RecipeDetail from '../recipe/RecipeDetail'
import {hideMenuChoice} from '../main/guiSlice'

const Einkaufsplan = ({plan}) => {
    let dispatch = useDispatch()
    const gui=useSelector(guiSlice.selectGui)

    // let sheetItemList=[{"label":"..","amount":1.0,"productLine":"..","done":..},]

    let dayPlanList, sheet, menuChoice, storeroom
    dayPlanList = sheet= menuChoice= storeroom=<div>noch keine Daten geladen</div>
    if(plan!==undefined && plan.sheetItemList!==undefined) {
        menuChoice=<MenuChoice />
        dayPlanList=<DayPlanList dayPlanList={plan.dayPlanList}/>
        sheet=<Sheet sheetItemList={plan.sheetItemList}/>
        storeroom=<StoreRoom storeroom={plan.storeroom}/>
    }
    
    

    let isMenuChoice=(gui.menuChoice!==undefined)
    let isReciceDetail=(gui.recipeDetail!==undefined)

    let leftPane, centerPane, rightPane
    if(isMenuChoice) {
      leftPane=getMenuChoice(dispatch, menuChoice)

      if(isReciceDetail) {
        centerPane = getRecipeDetail(gui.recipeDetail)
      }
      else {
        centerPane = getDayPlanList(dayPlanList)
      }
  
      rightPane=getSheet(gui, sheet)
    }
    else {
      leftPane=getDayPlanList(dayPlanList)
      centerPane=getSheet(gui, sheet)
      rightPane=getStoreroom(storeroom)
    }

    return (
        <div> <h1>Einkaufsplan</h1>
          <div style={{display:"flex", justifyContent:"center"}}>
            <button  type="button" onClick={()=> dispatch({type:"GET_PLAN_REQ", username:gui.username})
                                          }>Laden</button>
            <button  type="button" onClick={()=>dispatch({type:"SAVE_REQ"})}>Speichern</button>
            <input type="text"  value={gui.username} onChange={e=>{e.preventDefault();onSetUsername(dispatch,guiSlice,e.target.value)}}/>
          </div>
          <table style={{"width":"100%"}}>
          <tbody><tr>
              {leftPane}
              {centerPane}
              {rightPane}
            </tr>
          </tbody></table>
        </div>
    )
}

const onSetUsername = (dispatch,guiSlice,username) => {
  const cookies = new Cookies();
  cookies.set('einkaufsplan-username', username)
  dispatch(guiSlice.setUsername(username))
}


const getMenuChoice = (dispatch, menuChoice) => {
  return (<td style={{ width: "33%", verticalAlign: "top", borderRadius: 10, borderStyle: "groove" }}>
    <div style={{ display: "flex" }}>
      <h2>Menus</h2>
      <button style={{ height: "20px", marginRight: 20, marginLeft: "auto" }} type="button" onClick={() => dispatch(hideMenuChoice())}>X</button>
    </div>
    {menuChoice}
  </td>);
}
const getDayPlanList = (dayPlanList) => {
  return (<td style={{ width: "33%", verticalAlign: "top" }}><h2>Menuplan</h2>
    {dayPlanList}
  </td>);
}

const getSheet = (gui, sheet) => {
  return (<td style={{ width: "33%", verticalAlign: "top" }}><h2>Zettel{gui.showDoneSheetItems ? ", erledigt" : ""}</h2>
    {sheet}
  </td>);
}

const getStoreroom = (storeroom) => {
  return (<td style={{ width: "33%", verticalAlign: "top" }}><h2>Vorrat</h2>
    {storeroom}
  </td>);
}

const getRecipeDetail = (recipeDetail) => {
  return (<td style={{ width: "33%", verticalAlign: "top" }}><h2>Menuzutaten</h2>
    <RecipeDetail recipeDetail={recipeDetail} />}
  </td>)
}

export default Einkaufsplan

import { createSlice } from '@reduxjs/toolkit';

export const recipeSlice = createSlice({
  name: 'recipe',
  initialState: {},
  reducers: {
    getRecipeRsp: (recipe, action) => action.payload.recipe,
    setEditedRecipe: (recipe, action) => {
      let recipeDetail = action.payload.recipeDetail
      let recipeItemList = [...recipeDetail.recipeItemList]
      recipeItemList.splice(recipeDetail.recipeItemList.length-1, 1);
      if(recipeDetail.recipeBeforeEdit===undefined) {
        return {recipeList:[...recipe.recipeList, {label:recipeDetail.recipeLabel, persons:4, recipeItemList }]}
      }
      else {
        let index = recipe.recipeList.findIndex(r=>r.label===recipeDetail.recipeBeforeEdit.label)
        let recipeList=[...recipe.recipeList]
        recipeList[index]={label:recipeDetail.recipeLabel, persons:4, recipeItemList }
        return {recipeList}
      }
    },
    deleteEditedRecipe: (recipe, action) => {
      let recipeDetail = action.payload.recipeDetail
      let recipeItemList = [...recipeDetail.recipeItemList]
      recipeItemList.splice(recipeDetail.recipeItemList.length-1, 1);
      if(recipeDetail.recipeBeforeEdit===undefined) {
        // no changes
        return recipe
      }
      else {
        let index = recipe.recipeList.findIndex(r=>r.label===recipeDetail.recipeBeforeEdit.label)
        let recipeList=[...recipe.recipeList]
        recipeList.splice(index, 1)
        return {recipeList}
      }
    }
  }
})

export const { getRecipeRsp, setEditedRecipe, deleteEditedRecipe} = recipeSlice.actions;

export const selectRecipeList = state => state.recipe ? state.recipe.recipeList:undefined;

export default recipeSlice.reducer;

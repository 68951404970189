import { createSlice } from '@reduxjs/toolkit';
import * as busi from './busi'
import {calcTodaysStoreItems, storeToSheetItem, refillStoreRoom} from './busiStoreRoom'


export const planSlice = createSlice({
  name: 'plan',
  initialState: {},
  reducers: {
    getPlanRsp: (state, action) => {
      let x = action.payload.plan.dayPlanList
      console.log(x[0].date)
      let dayPlanList = busi.filterCurrentDates(action.payload.plan.dayPlanList)
      let storeroom = calcTodaysStoreItems(action.payload.plan.storeroom)
      let stateResult = {...action.payload.plan, dayPlanList, storeroom}
    
      return stateResult
    },
    doneSheetItem: (plan, action) => {
      let sheetItemList = plan.sheetItemList.map(i=>{
        let done = i.label===action.payload.sheetItem.label?true:i.done
        return {...i, done}
      })
      return {...plan, sheetItemList}
    },
    undoSheetItem: (plan, action) => {
      let sheetItemList = plan.sheetItemList.map(i=>{
        let done = i.label===action.payload.sheetItem.label?false:i.done
        return {...i, done}
      })
      return {...plan, sheetItemList}
    },
    rmSheetItem: (plan, action) => {
      /* add negative amount */      
      let amount = parseFloat(action.payload.sheetItem.amount)
      if(Number.isNaN(amount)) amount=1.0
      amount *= -1
      let sheetItemList = busi.mergeSheetItem( {...action.payload.sheetItem, amount}, [...plan.sheetItemList]) 
      return {...plan, sheetItemList}
    },
    mrgSheetItem: (plan, action) => {
      let sheetItemList0 = plan.sheetItemList
      if(action.payload.sheetItem.sheetItemBeforeEdit) {
        // remove before if exists..
        let amount = parseFloat(action.payload.sheetItem.sheetItemBeforeEdit.amount)      
        if(Number.isNaN(amount)) amount=1.0
        amount *= -1
  
        sheetItemList0 = busi.mergeSheetItem( {...action.payload.sheetItem.sheetItemBeforeEdit, amount}, [...plan.sheetItemList])   
      }
      // add new
      let sheetItemList = busi.mergeSheetItem( action.payload.sheetItem, [...sheetItemList0]) 
      return {...plan, sheetItemList}
    },
    mrgSheetItemFromStore: (plan, action) => {
      let sheetItemList0 = plan.sheetItemList
      let sheetItem=storeToSheetItem(action.payload.storeItem)
      // add new
      let sheetItemList = busi.mergeSheetItem( sheetItem, [...sheetItemList0]) 
      let storeroom = refillStoreRoom(action.payload.storeItem, plan.storeroom)
      return {...plan, sheetItemList, storeroom}
    },
    removeAllDoneSheetItems:(plan) => {
      const sheetItemList = plan.sheetItemList.filter(s => !s.done) 
      return {...plan, sheetItemList}
    },
    removeAllSheetItems:(plan) => {
      return {...plan, sheetItemList:[]}
    },
    addGear: (plan, action) => {
      const {menuChoice, menuName, recipe} = action.payload
      let sheetItemList = plan.sheetItemList
      // add items to sheetItemList
      if(recipe!==undefined) {         
        recipe.recipeItemList.forEach(item => sheetItemList=busi.mergeSheetItem({...item, done:false}, [...sheetItemList]))
      }

      // set MenuName to DayPlanList selectedDate
      let dayPlanList= plan.dayPlanList.map(dayPlan =>{
        let selectedMeal=busi.getSelectedMeal(dayPlan, menuChoice)
        if("lunch"===selectedMeal) {
          let lunch = dayPlan.lunch||{gearList:[]}
          lunch = {...lunch, gearList:[...lunch.gearList, {recipeLabel:menuName}]}
          return {...dayPlan, lunch}
        }
        else if("dinner"===selectedMeal) {
          let dinner=dayPlan.dinner||{gearList:[]}
          dinner = {...dinner, gearList:[...dinner.gearList, {recipeLabel:menuName}]}
          return {...dayPlan, dinner}
        }
        else return dayPlan

      })

      return {...plan, sheetItemList, dayPlanList}
    },
    removeGear: (plan, action) => {
      const {menuChoice, recipeLabel, recipeItemList} = action.payload
      let dayPlanList= busi.removeGear(plan.dayPlanList, menuChoice, recipeLabel)

      let sheetItemList = plan.sheetItemList
      // remove items from sheetItemList
      if(recipeItemList!==undefined) {         
        recipeItemList.forEach(item => sheetItemList=busi.mergeSheetItem({...item, amount:-item.amount, done:false}, [...sheetItemList]))
      }

      return {...plan, dayPlanList, sheetItemList}

    },
    newStoreItem: (plan) => { /* also see gui extra */
      let storeItemList = [{label:''}, ...plan.storeroom.storeItemList]
      return {...plan, storeroom:{...plan.storeroom, storeItemList}}
    },
    setStoreItemDetail: (plan, action) => { /* also see gui extra */
      let {label, productLineKey,oneBuyDuration, onStock, storeItemBeforeEdit} = action.payload
      // replace exiting item
      let storeItemList=plan.storeroom.storeItemList.map(storeItem =>{
        if(storeItem.label===storeItemBeforeEdit.label || storeItem.label==='') {
          // this is the storeItem to replace
          return {label, productLineKey,oneBuyDuration, onStock}
        }
        return storeItem
      })
      
      return {...plan, storeroom:{...plan.storeroom, storeItemList}}
    },
    removeStoreItemDetail: (plan, action) => { /* also see gui extra */
      let {storeItemBeforeEdit} = action.payload

      let storeItemList2 = [...plan.storeroom.storeItemList]
      let rmIndex = storeItemList2.findIndex(storeItem=>storeItem.label===storeItemBeforeEdit.label)
      storeItemList2.splice(rmIndex, 1)
      return {...plan, storeroom:{...plan.storeroom, storeItemList:storeItemList2}}
    },
    unshowStoreItemDetail: (plan) => { /* also see gui extra */

      /* empty item is generated on open. remove it now, if it remained unchanged */
      let storeItemList2 = [...plan.storeroom.storeItemList]
      let rmIndex = storeItemList2.findIndex(storeItem=>storeItem.label==='')
      storeItemList2.splice(rmIndex, 1)
      return {...plan, storeroom:{...plan.storeroom, storeItemList:storeItemList2}}
    },
  },
});

export const { getPlanRsp, doneSheetItem, undoSheetItem, rmSheetItem, 
               mrgSheetItem, mrgSheetItemFromStore, removeAllDoneSheetItems, removeAllSheetItems,
               addGear, removeGear, newStoreItem, setStoreItemDetail, removeStoreItemDetail, unshowStoreItemDetail} = planSlice.actions;

export const selectPlan = state => state.plan;
export const selectProductLine = state => state.plan.productLine;

export default planSlice.reducer;

import React from 'react'
import {useDispatch} from 'react-redux'
import styles from '../main/Main.module.css'
import {  doneSheetItem, undoSheetItem, rmSheetItem } from './planSlice';
import {  showSheetItemPopup } from '../main/guiSlice';


/**
 * SheetItems filtered by ProductLine
 * @productLineSheetItems {} param0 
 * @paisShowActiveram {} param0 
 */
const ProductlineSheetItems = ({productLineSheetItems, isShowActive}) => {
    const dispatch = useDispatch()    
    let pl=productLineSheetItems
    
    // skip empty Productline when show "done" 
    if(!isShowActive && pl.items === undefined) return null
    return (
        <div key={pl.plKey}>
            <div className={styles.epTableTitleRow} >
                <div className={styles.epTableCell}>{pl.plLabel}</div>
                <div className={styles.epTableCell}  >
                    {isShowActive?<button type="button" style={{marginRight:25}} 
                                          onClick={()=>dispatch(showSheetItemPopup({sheetItem:{productLine:pl.plKey}}))}>Add...</button>:null}
                </div>
            </div>
            {provideProductlineItems(pl.items, {isShowActive}, dispatch)}        
        </div>)
    }



const provideProductlineItems = (sheetItemList, {isShowActive}, dispatch) => {
    if(sheetItemList===undefined) return
    return sheetItemList.map((sheetItem, index) => {
      let rowId = "Row" + index;
      return (<div id={rowId} key={index}>

        <div className={styles.epTableCell}   >
            {""+sheetItem.amount+sheetItem.label}
        </div>
        <div className={styles.epTableCell}  ><span style={{display:"flex"}}>
        {isShowActive?
            <button type="button" onClick={()=>dispatch(showSheetItemPopup({sheetItem, sheetItemBeforeEdit:sheetItem}))}>...</button>:null}
            {!isShowActive?
            <button type="button" onClick={()=>dispatch(undoSheetItem({sheetItem}))}>&lt;</button>:null}

            <button type="button" onClick={()=>{
                if(isShowActive)
                    return dispatch(doneSheetItem({sheetItem}))
                else
                    return dispatch(rmSheetItem({sheetItem}))}}
            >X</button>
        </span></div>
      </div>)
    })
}

export default ProductlineSheetItems
  import { call, put, select, takeEvery } from 'redux-saga/effects'
import axios from 'axios';

import {
  getPlanRsp, selectPlan
} from '../features/plan/planSlice.js';
import {  
  getRecipeRsp, selectRecipeList,
} from '../features/recipe/recipeSlice.js';
import {
  getPlanFailed, selectGui,
} from '../features/main/guiSlice.js';

const defaultUsername='default'

function* getPlan(action) {
  try {
    const tst = JSON.stringify(action)
    console.log(tst)
    let username = action.username ||defaultUsername
    console.log(username)
    const rsp = yield call(axios.get, 'https://epbackend001.oa.r.appspot.com/moPlan', { 'headers': { 'X-Name': username }}  );

    if(username===defaultUsername) username=undefined
    yield put( getPlanRsp({ plan: rsp.data, username}))
  } catch (e) {
    yield put(getPlanFailed({message: e.message}))
  }
}
function* save() {
   try {
    let gui = yield select(selectGui)
    let username = gui.username
    let plan = yield select(selectPlan)
    let recipeList = yield select(selectRecipeList)
    let recipe = {recipeList}
    console.log(JSON.stringify(recipeList))
    if(username===undefined) {
      yield put(getPlanFailed({message: "Name zum Speichern erforderlich."}))
      return;
    }

    yield call(axios.post, 'https://epbackend001.oa.r.appspot.com/moPlan', plan, {
        'headers': { 'X-Name': username, 
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                   }}  );

    yield call(axios.post, 'https://epbackend001.oa.r.appspot.com/moRecipeList', recipe, {
        'headers': { 'X-Name': username, 
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                   }}  );
   } catch (e) {
    yield put(getPlanFailed({message: e.message}))
  }
}
function* getRecipeList(action) {
  try {
    let username = action.username ||defaultUsername
    const rsp = yield call(axios.get, 'https://epbackend001.oa.r.appspot.com/moRecipeList', { 'headers': { 'X-Name': username }}  );

    if(username===defaultUsername) username=undefined
   yield put( getRecipeRsp({ recipe: rsp.data, username}))
 } catch (e) {
   yield put(getPlanFailed({message: e.message}))
 }
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* epSaga() {
  yield takeEvery("GET_PLAN_REQ", getPlan)
  yield takeEvery("SAVE_REQ", save)
  yield takeEvery("GET_RECIPE_REQ", getRecipeList)
}

export default epSaga;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { selectPlan } from '../plan/planSlice';
import { selectRecipeList } from '../recipe/recipeSlice';
import { selectGui } from './guiSlice';
import Einkaufsplan from '../plan/Einkaufsplan'
import SheetItemPopup from '../plan/SheetItemPopup.js'
import styles from './Main.module.css'

export function Main() {
  const dispatch = useDispatch()
  const plan=useSelector(selectPlan)
  const gui=useSelector(selectGui)
  const recipeList=useSelector(selectRecipeList)

  useEffect(() => {
    if(plan === undefined || plan.productLine === undefined) { 
      const cookies = new Cookies();
      // saga request
      dispatch({type:"GET_PLAN_REQ", username:cookies.get('einkaufsplan-username')})
    }
    if(recipeList === undefined ) { 
      const cookies = new Cookies();
      // saga request
      dispatch({type:"GET_RECIPE_REQ", username:cookies.get('einkaufsplan-username')})
  }
}, [dispatch, recipeList, plan]);


  return (
    <div className={styles.main}>
      {addSheetItemPopup(gui) }
      <Einkaufsplan plan={plan}/>
    </div>

  )
  
}

const addSheetItemPopup = (gui) => {
  if(gui.sheetItemPopup) {
    return (<SheetItemPopup sheetItemPopup={gui.sheetItemPopup}/>)
  }
}

import React from 'react'
import Meal from './Meal'
import * as busi from './busi'
import styles from '../main/Main.module.css'

const DayPlan = ({dayPlan}) => {

    let dateStr = busi.dateToStr(dayPlan.date)
    
    
    return <div key={dayPlan.date}>
            <div className={styles.epTableTitleRow} >
                <div className={styles.epTableCell} >{dateStr}</div>
            </div>
            <span style={{display:"flex", padding:5}} >
                <div style={{padding:10, fontSize:"small"}}>Mittag</div>
                <Meal mealName="lunch" date={dayPlan.date} meal={dayPlan.lunch} />
            </span>

            <span style={{display:"flex", padding:5}} >
                <div style={{padding:10, fontSize:"small"}}>Abend</div>
                <Meal mealName="dinner" date={dayPlan.date} meal={dayPlan.dinner} />
            </span>

    </div>
}
export default DayPlan 
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as gui from '../main/guiSlice';
import * as plan from './planSlice'
import * as recipe from '../recipe/recipeSlice';
import * as busiRecipe from '../recipe/busiRecipe'
import * as busi from './busi'
import styles from '../main/Main.module.css'

const Meal = ({mealName, date, meal}) => {
    const dispatch = useDispatch()
    const recipeList=useSelector(recipe.selectRecipeList)

    let mealLbl = "Menu wählen..."
    if(meal && meal.gearList && meal.gearList.length>0) {
        mealLbl =meal.gearList[0].recipeLabel
        if(meal.gearList.length>1) {mealLbl +=", ..."}
    }

    // change style of meal if menu Choice refers to this meal
    const menuChoice=useSelector(gui.selectGui).menuChoice
    let selectedMealStyle={padding:5}
    
    let isSelectedMeal = busi.isSelectedMeal(date, mealName, menuChoice)
    if(isSelectedMeal) {
        selectedMealStyle={padding:5, backgroundColor:"#c0c0c0"}

        let gearList=(meal?.gearList)||[]

        let gearDivList = gearList.map((gear, gearIdx)=>{
            let recipeItemList = busiRecipe.getRecipeItemList(recipeList, gear.recipeLabel)
            return <div key={gearIdx} style={{display:"flex", width:"100%"}}>
            <button type="button" className={styles.epTableRowButton} style={selectedMealStyle}
              onClick={()=>dispatch(gui.showMenuChoice({selectedDate:date.getTime(), selectedMeal:mealName}))}>{gear.recipeLabel}
            </button>
            <button type="button"  
                            onClick={()=>dispatch(plan.removeGear({recipeLabel:gear.recipeLabel, recipeItemList, menuChoice}))}>X
            </button>
        </div>})

        // show gearList delete-buttons and a textfield
        return <div style={{width:"100%"}}>
                {gearDivList}
            </div>

    }
    else {
        return <div style={{display:"flex", width:"100%"}}>
            <button type="button" className={styles.epTableRowButton} style={selectedMealStyle}
                onClick={()=>dispatch(gui.showMenuChoice({selectedDate:date.getTime(), selectedMeal:mealName}))}>{mealLbl}</button>
            {mealLbl!=="Menu wählen..." ? <button type="button"  
                                onClick={()=>dispatch(gui.showMenuChoice({selectedDate:date.getTime(), selectedMeal:mealName}))}>...</button>:null}
        </div>

    }

}

export default Meal
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as plan from './planSlice'
import * as gui from '../main/guiSlice'
import styles from '../main/Main.module.css'


const StoreItemDetail = ({storeItemDetail}) => {
    const dispatch = useDispatch()
    const productLines = useSelector(plan.selectProductLine||'')
    const storeItemBeforeEdit = useSelector(gui.selectStoreItemDetail).storeItemBeforeEdit

    const [label, setLabel] = useState(storeItemDetail.label)

    const [oneBuyDuration, setOneBuyDuration] = useState(storeItemDetail.oneBuyDuration)
    const [onStock, setOnStock] = useState(storeItemDetail.onStock)
    const [productLineKey, setProductLineKey] = useState(storeItemDetail.productLineKey||Object.keys(productLines)[0])

    return (<div className={styles.epTableRow} style={{width:"100%"}}> 
            <div className={styles.epTableCell} style={{border:"solid lightblue 1px", borderRadius: 15, padding:5}}><h3>Vorrat bearbeiten</h3>
        
            <select value={productLineKey} style={{padding:5}}
                    onChange={e=>{e.preventDefault();setProductLineKey(e.target.value)}} >
                {Object.keys(productLines).map(pr => (<option value={pr} key={pr}>{productLines[pr]}</option>))}
            </select>
            <div>
            <input  style={{width:"90%"}} type="text" value={label} onChange={e=>{e.preventDefault();setLabel(e.target.value)}} />
            </div><div>
            Lager reicht für <input style={{width:50, margin:10}} type="number" value={onStock} onChange={e=>{e.preventDefault();setOnStock(e.target.value)}} /> Tage
            </div><div>
            Einkauf reicht <input style={{width:50, margin:10}} type="number" value={oneBuyDuration} onChange={e=>{e.preventDefault();setOneBuyDuration(e.target.value)}} />Tage
            </div>
            <div style={{display:"flex"}}>
                <button   style={{display:"table-cell", width:"50%", padding:5, margin:5}} type="button"
                    onClick={()=>dispatch(plan.unshowStoreItemDetail())}
                >Abbrechen</button>
                <button   style={{display:"table-cell", width:"50%", padding:5, margin:5}} type="button"
                        onClick={()=>dispatch(plan.removeStoreItemDetail({storeItemBeforeEdit}))}
                        disabled={storeItemBeforeEdit.label=== undefined } 
                >Löschen</button>
                <button   style={{display:"table-cell", width:"50%", padding:5, margin:5}} type="button"
                        onClick={()=>dispatch(plan.setStoreItemDetail({label, productLineKey,oneBuyDuration, onStock, storeItemBeforeEdit}))}
                        disabled={oneBuyDuration ===undefined || onStock ===undefined } 
                    >OK</button>
            </div>
            </div>
          </div>
        
    )
}


export default StoreItemDetail
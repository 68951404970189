import React from 'react'
import {useDispatch} from 'react-redux'
import * as plan from './planSlice'
import * as gui from '../main/guiSlice'
import styles from '../main/Main.module.css'


const StoreItem = ({storeItem}) => {
    const dispatch=useDispatch()
    
    return (<div className={styles.epTableRow} style={{width:"100%"}} > 
        <button   style={{display:"table-cell", width:"100%", padding:"5px", margin:"5px"}} key={storeItem.label} type="button"
                  onClick={()=>dispatch(plan.mrgSheetItemFromStore({storeItem}))}
        >{storeItem.label}</button>
        <div style={{display:"table-cell", width:"100px"}} >{storeItem.onStock} Tage</div>
        <div style={{display:"table-cell", width:"50px"}} ><button key={storeItem.label+"..."} type="button"
          onClick={()=>dispatch(gui.showStoreItemDetail({storeItem, storeItemBeforeEdit:storeItem}))}
          >...</button></div>
        
    </div>)
}

export default StoreItem